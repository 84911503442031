<template>
  <div class="text-left">
    <br><br>
    <el-form label-width="120px">
      <el-form-item label="累计服务人数">
        <el-input-number :min="0" v-model="number" />
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          :loading="saveLoading"
          @click="handleSaveData">
          保存
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import resStatus from '@/constants/resStatus'
import { settingUserCount, settingGet } from '@/api/admin/setting'

export default {
  data () {
    return {
      number: 0,
      saveLoading: false
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      settingGet().then(res => {
        switch (res.status) {
          case resStatus.OK:
            this.number = res.data.f_user_acc
            break
          default:
            this.$message.error(res.message)
        }
      }).catch(err => {
        this.$message.error(err)
      })
    },
    handleSaveData () {
      this.saveLoading = true
      settingUserCount(this.number).then(res => {
        this.saveLoading = false
        switch (res.status) {
          case resStatus.OK:
            this.$message.success('保存成功')
            break
        }
        if (res.message.length !== 0) {
          this.$message(res.message)
        }
      }).catch(err => {
        this.saveLoading = false
        this.$message.error(err)
      })
    }
  }
}
</script>
